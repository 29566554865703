<template>
    <NavMenu :hide="hide" v-model="show" main-menu>
        <v-list class="pt-1">
            <v-list-item link :to="{ name: 'AdminHome' }">
                <v-list-item-action>
                    <v-icon>mdi-home</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Home</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-divider />

            <v-list-item
                link
                :to="{ name: 'PartnerList' }"
            >
                <v-list-item-action>
                    <!-- <v-icon v-html="$vuetify.icons.values.partner" /> -->
                    <v-icon>{{$vuetify.icons.values.partner}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Partners</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                link
                :to="{ name: 'CustomerList' }"
                v-if="
                    $store.state.user.user_type === 'admin' ||
                    $store.state.user.user_type === 'partner'
                "
            >
                <v-list-item-action>
                    <v-icon>mdi-account-multiple</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Customers</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item link :to="{ name: 'TrunkList' }">
                <v-list-item-action>
                    <!-- <v-icon v-html="mdiPhoneVoip" /> -->
                    <v-icon>{{mdiPhoneVoip}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>SIP Trunks</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

          <v-list-item link :to="{ name: 'ExtensionList' }">
            <v-list-item-action>
              <!-- <v-icon v-html="mdiPhoneVoip" /> -->
              <v-icon>{{mdiPhoneVoip}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Extensions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'RingGroupList' }">
            <v-list-item-action>
              <!-- <v-icon v-html="mdiPhoneVoip" /> -->
              <v-icon>{{mdiPhoneVoip}}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Ring Groups</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'AutoAttendantList' }">
            <v-list-item-action>
              <v-icon>mdi-badge-account-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Auto Attendants</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'CallQueueList' }">
            <v-list-item-action>
              <v-icon>mdi-clipboard-list-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Call Queue</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item link :to="{ name: 'FaxToMailList' }">
            <v-list-item-action>
              <v-icon>mdi-fax</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Fax to Mail Accounts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

            <v-list-item link :to="{ name: 'DIDInventory' }">
                <v-list-item-action>
                  <!-- <v-icon v-html="mdiNumeric" /> -->
                  <v-icon>{{mdiNumeric}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>DID Inventory</v-list-item-title>
                </v-list-item-content>
            </v-list-item>



          <v-list-item link :to="{ name: 'VoipDeviceList' }">
            <v-list-item-action>
              <v-icon>mdi-deskphone</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Extension Devices</v-list-item-title>
            </v-list-item-content>
          </v-list-item>



            <v-divider />

            <v-list-item link :to="{ name: 'SignupList' }">
                <v-list-item-action>
                    <v-icon>mdi-plus-circle</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Sign-ups</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                    link
                    :to="{ name: 'AdminPorting' }"
            >
                <v-list-item-action>
                    <!-- <v-icon v-html="'mdi-phone-plus'" /> -->
                    <v-icon>mdi-phone-plus</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Porting</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                link
                :to="{ name: 'PartnerCommissionReports' }"
            >
                <v-list-item-action>
                  <!-- <v-icon v-html="mdiSale" /> -->
                  <v-icon>{{mdiSale}}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Commissions</v-list-item-title>
                </v-list-item-content>
            </v-list-item>


          <v-list-item
            link
            :to="{ name: 'AdminReportList' }"
          >
            <v-list-item-action>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Reports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
<!--
            <v-list-group>
                <template v-slot:activator>
                    <v-list-item-action>
                        <v-icon>mdi-file-document-outline</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Reports</v-list-item-title>
                    </v-list-item-content>
                </template>

              <v-list-item link :to="{ name: 'ReportCustomerChurn'}">
                <v-list-item-action> </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Customer Churn Report</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item link :to="{ name: 'ReportNewCustomerGrowth'}">
                <v-list-item-action> </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>New Customer Growth Report</v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-divider />
                <v-list-item link :to="{ name: 'CreditCardReport' }">
                    <v-list-item-action> </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title
                            >Credit Card Charges</v-list-item-title
                        >
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{ name: 'UnpaidInvoiceReport' }">
                    <v-list-item-action> </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Unpaid Invoices</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

-->
            <v-list-item
               
                link
                :to="{ name: 'AdminUserList' }"
            >
                <v-list-item-action>
                    <v-icon>mdi-account-supervisor-circle</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Users</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-group>
                <template v-slot:activator>
                    <v-list-item-action>
                        <v-icon>mdi-cog</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>System</v-list-item-title>
                    </v-list-item-content>
                </template>
                <v-list-item link :to="{ name: 'CronList' }">
                    <v-list-item-action>
                        <v-icon>mdi-clock</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-title>Cron Tasks</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link
                             :to="{ name: 'AdminOperationStatus' }"
                >
                  <v-list-item-action>
                    <v-icon>mdi-shield-check</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Operational Status</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

            </v-list-group>

        </v-list>
    </NavMenu>
</template>

<script>
import { mdiNumeric, mdiPhoneVoip, mdiSale, mdiPhoneClassic } from '@mdi/js';
import NavMenu from '../templates/NavMenu';
export default {
    name: 'AdminMenu',
    components: { NavMenu },
    data: () => ({
        show: false,
        appVersion: process.env.VUE_APP_VERSION,
        mdiNumeric: mdiNumeric,
        mdiPhoneVoip: mdiPhoneVoip,
        mdiPhoneClassic: mdiPhoneClassic,
        mdiSale: mdiSale,
    }),
    props: {
        value: {
            default: false,
        },
        hide: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        show: function (val) {
            this.$emit('input', val);
        },
        value: function (val) {
            this.show = val;
        },
    },
    created() {
        this.show = this.value;
    },
};
</script>

<style scoped>
/*.v-navigation-drawer--temporary.v-navigation-drawer--clipped {
        z-index: 5;
        padding-top: 64px;
    }*/
</style>
